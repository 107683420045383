import './offer-banner.css'
import React from 'react'

import { Golfer } from 'const/types'
import { ToggleSaveButton } from 'components/toggle-save-button'
import { OfferingEnds } from 'ui/time-ago'

export const OfferBanner = ({ golfer: { uuid, image, name, closedAt, gender } = {} }) => {
  const pronoun = (gender || '').toLowerCase() === 'female' ? 'her' : 'his'
  return (
    <div className="offer-banner">
      <img src={image} className="main-banner-img" alt="" />
      <div className="container">
        <h1>
          {name} is looking for supporters to invest in {pronoun} future.
        </h1>
        {closedAt && (
          <div className="time-left-container">
            <OfferingEnds date={closedAt} />
          </div>
        )}
      </div>
      <ToggleSaveButton uuid={uuid} className="no-bg" />
    </div>
  )
}

OfferBanner.propTypes = {
  golfer: Golfer,
}
