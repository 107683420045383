import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { Link } from 'ui/link'
import { routes } from 'const/routes'
import { investor, getActiveInvestorsAmount } from 'store/investor'
import { Waitress } from 'components/waitress'

const PureSignUpBlock = ({ totalActiveInvestors, getActiveInvestorsAmount }) => (
  <Waitress fn={[getActiveInvestorsAmount]}>
    <div className="join-block text-center">
      {totalActiveInvestors ? (
        <h3 className="mb-20">
          Join the community of {totalActiveInvestors} investor
          {totalActiveInvestors > 1 ? 's.' : '.'}
        </h3>
      ) : (
        false
      )}
      <Link to={routes.signUp} theme="primary">
        Sign up as an investor
      </Link>
    </div>
  </Waitress>
)

PureSignUpBlock.propTypes = {
  getActiveInvestorsAmount: PropTypes.func.isRequired,
  totalActiveInvestors: PropTypes.number,
}

export const SignUpBlock = connect(
  createStructuredSelector({
    totalActiveInvestors: investor.totalActiveInvestors,
  }),
  {
    getActiveInvestorsAmount,
  },
)(PureSignUpBlock)
