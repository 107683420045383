import './account-menu.css'
import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { user } from 'store/user'
import { logout } from 'store/auth'

import { routes } from 'const/routes'
import { MenuLink, MenuButton } from 'ui/menu'
import { Avatar } from 'ui/avatar'
import { DropdownToggle } from 'ui/dropdown-toggle'
import { SignedIn } from 'components/signed'
import { INVESTOR, ISSUER } from 'const/types'
export const AccountMenu = connect(
  createStructuredSelector({
    fullName: user.fullName,
    avatar: user.avatar,
  }),
  {
    logout,
  },
)(({ fullName, avatar, logout }) => {
  const label = (
    <>
      <Avatar src={avatar} />
      <span>{fullName}</span>
    </>
  )
  return (
    <DropdownToggle label={label} icon={null} className="account-menu">
      <hr />
      <SignedIn userRole={INVESTOR}>
        <MenuLink to={routes.myInvestments}>My Investments</MenuLink>
        <MenuLink to={routes.myInvestmentSaved}>Saved</MenuLink>
        <MenuLink to={routes.notifications}>Notifications</MenuLink>
        <MenuLink to={routes.settings}>Settings</MenuLink>
        <hr />
      </SignedIn>
      <SignedIn userRole={ISSUER}>
        <MenuLink to={routes.issuerQuestionAnswer}>My Q&amp;A</MenuLink>
        <MenuLink to={routes.issuerInvestments}>Investments</MenuLink>
        <hr />
      </SignedIn>
      <MenuButton onClick={logout}>Logout</MenuButton>
    </DropdownToggle>
  )
})
