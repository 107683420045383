import { all, call, select, put } from 'redux-saga/effects'
import { attachSideEffect } from '../utils'
import { callWithAuth } from 'store/auth/saga'

import * as api from 'api'
import { USER, user } from '.'
import { AUTH } from '../auth'

export default function*() {
  yield all([
    attachSideEffect(USER.GET_ACCOUNT, onGetUserAccount),
    attachSideEffect(USER.PATCH_ACCOUNT, onPatchUserAccount),
    attachSideEffect(USER.GET_ADDRESS, onGetUserAddress),
    attachSideEffect(USER.UPDATE_ADDRESS, onUpdateUserAddress),
    attachSideEffect(USER.GET_ACH_SETTINGS, onGetUserAchSettings),
    attachSideEffect(USER.UPDATE_ACH_SETTINGS, onUpdateUserAchSettings),
    attachSideEffect(USER.SIGN_AGREEMENT, onSignUpAgreement),
    attachSideEffect(USER.UPDATE_PROFILE_IMAGE, onUpdateProfileImage),
    attachSideEffect(USER.UPDATE_QUESTION_ANSWERS, onUpdateQuestionAnswers),
    attachSideEffect(USER.UPDATE_PASSWORD, onUpdatePassword),
  ])
}

function* onGetUserAccount() {
  const result = yield* callWithAuth(api.user.getAccount)
  if (!result) return null
  const { phone, ...user } = result
  return {
    ...user,
    phone: (phone && phone.phone) || null,
  }
}
function* onPatchUserAccount(data) {
  const result = yield* callWithAuth(api.user.patchAccount, data)
  if (!result) return null
  const { phone, ...user } = result
  return { ...user, phone: (phone && phone.phone) || null }
}

function* onGetUserAddress() {
  const result = yield* callWithAuth(api.user.getAddress)
  return result
}
function* onUpdateUserAddress(data) {
  const uuid = yield select(user.addressUuid)
  const result = yield* uuid
    ? callWithAuth(api.user.patchAddress, data)
    : callWithAuth(api.user.postAddress, data)
  return result
}

function* onGetUserAchSettings() {
  const result = yield* callWithAuth(api.user.getAchSettings)
  return result
}
function* onUpdateUserAchSettings(data) {
  const uuid = yield select(user.achSettingsUuid)
  const result = yield* uuid
    ? callWithAuth(api.user.patchAchSettings, { ...data, uuid })
    : callWithAuth(api.user.postAchSettings, data)
  return result
}
function* onSignUpAgreement() {
  const result = yield* callWithAuth(api.user.patchAccount, { isAgreementSigned: true })
  return result
}

function* onUpdateProfileImage(image) {
  const result = yield* callWithAuth(api.image.upload, { image })
  return result
}

function* onUpdateQuestionAnswers(data) {
  const result = yield* callWithAuth(api.qa.answerQuestion(data.uuid), { answer: data.answer })
  return result
}

function* onUpdatePassword({ password, confirmationToken }) {
  if (!password) throw { error: 'onResetPassword: missing password' }
  if (!confirmationToken) throw { error: 'onResetPassword: missing token' }
  const result = yield call(api.user.postUpdatePassword, { password, confirmationToken })
  yield put({ type: AUTH.REFRESH_TOKEN.SUCCESS, payload: result })
  yield put({ type: USER.GET_ACCOUNT.START })
  return result
}
