import './styles.css'
import React from 'react'
import { ContentViewer } from 'components/content-viewer'

export const LearnMore = () => {
  return (
    <div className="container static-page learn-more">
      <ContentViewer contentKey="learn-more" defaultContent={DEFAULT} />
    </div>
  )
}

export const DEFAULT = {
  blocks: [
    {
      type: 'header',
      data: {
        level: 4,
        text:
          'Equity crowdfunding is a relatively new concept which was ratified as federal law through the JOBS Act passed in 2012. Title III of the law enables everyday people to invest in companies, like the ones listed on Prospect Equity, on federally registered and regulated portals. As required by Regulation Crowdfunding, which resulted from these laws, we are obligated to provide you with educational materials to help you make your decision on whether or not to invest in these issuers. We encourage you to read through all the provided materials before making any investments.',
      },
    },
    { type: 'header', data: { level: 3, text: 'HOW IT WORKS' } },
    {
      type: 'paragraph',
      data: {
        text:
          'As many pro athletes know, endeavoring to make a career in professional sports takes more than a love of the game, hours of practice, or luck. While making their way up through the ranks, and especially at the start of their careers, it can take a significant amount of money to pay for various expenses that a pro career requires. It can take years before an athlete is able to fund the entirety of these expenses through their winnings and other sources of revenue like corporate sponsorships. In the past, it took family commitments and hard-to-find benevolent sponsors to bridge that gap, leaving behind many athletes with high potential but without enough resources. But with the advent of equity crowdfunding, these athletes can now create and run companies that manage and administer their careers and turn to investors like you to invest in that company and fund the early parts of their career, in return for a share of their future earnings. ',
      },
    },
    {
      type: 'paragraph',
      data: {
        text:
          'Like many pro athletes, the ones offering investments on Prospect Equity have created corporate entities (&quot;issuers&quot;) to help manage and administer their playing careers and revenue. As part of the issuance, each athlete will provide a budget that details how your investment will be used for the budgeted period, which will typically run for one year. In return for your investment, the issuer pledges to pay to investors a portion of all applicable athlete revenue for the duration of their professional career. The portion of revenue that is pledged to be paid out will differ from athlete to athlete and will often change over time. The portion paid out is determined together with advisory services provided by Prospect Equity, as permitted under Regulation CF, to take into account various factors and resulting in a structure that balances investor and athlete interests. ',
      },
    },
    {
      type: 'paragraph',
      data: {
        text:
          'Becoming an investor is easy and straightforward. Simply create an account after reading all the relevant disclosures and requirements, determine your investment limit, choose from the available offerings, and invest! ',
      },
    },
    { type: 'header', data: { level: 3, text: 'HOW WE SELECT ATHLETES' } },
    {
      type: 'paragraph',
      data: {
        text:
          'At Prospect Equity, we believe everyone should have the opportunity to follow their dreams and passions. Thus, we are open to accepting on our platform issuers that represent athletic prospects of many different ages and experience, after passing background checks and verification of past experience.',
      },
    },
    {
      type: 'paragraph',
      data: {
        text:
          'All issuers accepted on our platform also use Prospect Equity&apos;s advisory services around investment structuring, terms, and documentation, and we work with each issuer to determine an appropriate payout structure. Each structure takes into account an athlete&apos;s previous experience and results using a proprietary machine learning algorithm. Our goal is to provide an objective and fair payout structure that balances the interests of both investors and athletes. ',
      },
    },
    { type: 'header', data: { level: 3, text: 'TYPES OF SECURITIES' } },
    {
      type: 'paragraph',
      data: {
        text:
          'Each issuer listed on Prospect Equity will be offering securities in the form of preferred equity shares which entitles you to a portion of all future applicable athletic revenue as described in each issuer&apos;s offering documents. ',
      },
    },
    {
      type: 'paragraph',
      data: {
        text:
          'These shares do not come with any voting rights or any form of company control as may be typical to other types of equity. ',
      },
    },
    { type: 'header', data: { level: 3, text: 'APPLICABLE ATHLETE REVENUE' } },
    {
      type: 'paragraph',
      data: {
        text:
          'Each issuer will agree to include all applicable athlete revenue that will flow through company. The sources of revenue will be clearly laid out in the issuer documents, but generally this will include any winnings, promotional fees, endorsements, advertisements, honorariums, monetary prizes and any other amounts that the athlete receives in connection with professional athletic activities during the life of the athlete’s professional career. ',
      },
    },
    { type: 'header', data: { level: 3, text: 'MASTER ATHLETE AGREEMENT' } },
    {
      type: 'paragraph',
      data: {
        text:
          'The Master Athlete Agreement (&quot;MAA&quot;) will describe the relationship between athletes, investors, and the company as well as the rights and obligations of each. A summary of the terms of the athlete’s MAA will be available to investors. ',
      },
    },
    { type: 'header', data: { level: 3, text: 'POST OFFERING RELATIONSHIP' } },
    {
      type: 'paragraph',
      data: {
        text:
          'As a service to each issuer and their investors, Prospect Equity Services LLC (&quot;PES&quot;), an affiliated company, will be a designated board member of each issuer. As the board member, PES will have a fiduciary duty to the issuer, which includes honoring any obligations to investors and ensuring compliance of each party to the Master Athlete Agreement. PES will NOT be reimbursed or paid for its service on the board. ',
      },
    },
    {
      type: 'paragraph',
      data: {
        text:
          'Separately, Prospect Equity Services LLC may enter into a services agreement after a deal closes, at the discretion of the issuer, wherein certain administrative functions will be provided to the issuer in return for a fee. ',
      },
    },
    { type: 'header', data: { level: 3, text: 'SELLING RESTRICTIONS' } },
    {
      type: 'paragraph',
      data: {
        text:
          'To minimize the potential of bad actor actions, investors are indefinitely prohibited from transferring or selling the securities invested through Prospect Equity. In time, our goal is to develop a responsible secondary market that would enable transactions of these securities, but investors should expect to hold onto these investments to completion. In fact, federal regulations restrict any transfer or sale of crowdfunded securities for at least 12 months after the close of a deal except under very specific conditions. ',
      },
    },
    { type: 'header', data: { level: 3, text: 'CANCELLATION POLICY' } },
    {
      type: 'paragraph',
      data: {
        text:
          'Investors will have the right to cancel any investment commitments up to 48 hours before a deal is scheduled to close. After that time, investments will be final and investors will not be able to cancel their commitment. ',
      },
    },
    { type: 'header', data: { level: 3, text: 'INVESTMENT AMOUNT RESTRICTIONS' } },
    {
      type: 'paragraph',
      data: {
        text:
          'Per federal rules and regulations, investors can only invest up to a certain amount every 12 months across all equity crowdfunding portals, including Prospect Equity. Prospect Equity provides an online calculator that helps each investor, at the time of investment, determine their limit and how much they can still invest under that limit. ',
      },
    },
    { type: 'header', data: { level: 3, text: 'RISKS' } },
    {
      type: 'header',
      data: {
        level: 4,
        text: 'Securities that depend on future athletic performance are inherently risky.',
      },
    },
    {
      type: 'paragraph',
      data: {
        text:
          'Future individual athletic performance is not easily predictable. Most athletes who turn pro will not earn substantial amounts of money from their professional athletic activities for years, if at all. Thus, many investments will not have significant returns for some time, if at all. ',
      },
    },
    {
      type: 'paragraph',
      data: {
        text:
          'Athletes will have wide latitude in managing their careers after the budgeted period after a successful offering close. They may get injured, choose to play in fewer events, or retire from professional activity. However, as long they continue to play professionally, their obligation to investors will continue. ',
      },
    },
    {
      type: 'paragraph',
      data: {
        text:
          'While Prospect Equity conducts standard background checks on each athlete represented by an issuer and has a contractual Master Athlete Agreement with the individual athletes, we are not liable for any future fraud committed by the athlete. ',
      },
    },
    {
      type: 'header',
      data: {
        level: 4,
        text: 'Securities do not come with any form of control or promise of repayment.',
      },
    },
    {
      type: 'paragraph',
      data: {
        text:
          'Although investors are receiving preferred stock through the offering, this class of equity will not have the voting nor control powers typical of equity investments. ',
      },
    },
    {
      type: 'paragraph',
      data: {
        text:
          'The preferred equity received by investors is not a debt instrument. Thus, there is no obligation for the issuer to repay any fixed amount to an investor. Instead the athlete will enter an agreement to pay out a portion of future athlete revenue to investors through the issuer, the exact amount of which will be unknown at deal close. ',
      },
    },
    { type: 'header', data: { level: 4, text: 'There are limits on disclosure requirements.' } },
    {
      type: 'paragraph',
      data: {
        text:
          'Companies issuing securities through Prospect Equity are required to disclose certain information to investors, all of which will be available on each issuer&apos;s page. After a deal close, companies are required to file reports at least annually. Investors generally do not have any rights to additional information. ',
      },
    },
    {
      type: 'header',
      data: { level: 4, text: 'There may be changes in general economic conditions.' },
    },
    {
      type: 'paragraph',
      data: {
        text:
          'Many companies, including the ones listed on Prospect Equity, may perform adversely due to changes in general economic and/or market conditions. While athletic performance may be considered uncorrelated to the general economy, the money they earn through prizes may decrease under certain conditions despite a similar, or better, level of play. Corporate sponsorship and amounts may also fluctuate depending on market conditions for the corporate sponsors. ',
      },
    },
    {
      type: 'header',
      data: { level: 4, text: 'Past performance is not indicative of future results.' },
    },
    {
      type: 'paragraph',
      data: {
        text:
          'As with all investments, past performance by an athlete is not an indication of future results. This is the case for both the level of play by the athlete and the amount of money generated. ',
      },
    },
    {
      type: 'header',
      data: {
        level: 4,
        text: 'Issuers may need more capital in the future.',
      },
    },
    {
      type: 'header',
      data: {
        level: 4,
        text:
          'Many new companies, including the ones listed on Prospect Equity, often need additional capital in the future in order to operate. While there will be opportunity for such companies to raise more money through re-listing on Prospect Equity, there is no assurance that we will accept such an offering, that it will come with beneficial terms, or that it will be successful. ',
      },
    },
    { type: 'header', data: { level: 4, text: 'There may be regulatory changes in the future.' } },
    {
      type: 'paragraph',
      data: {
        text:
          'ile Prospect Equity will work with issuers to prepare them on all regulatory requirements and needs, there is no assurance that they will be able to comply whether due to oversight or fraud. Also, the government bodies that oversee equity crowdfunding may choose to amend existing regulations or create new ones with unknown consequences to issuers. ',
      },
    },
    {
      type: 'header',
      data: { level: 4, text: 'There may be tax consequences to investing in securities.' },
    },
    {
      type: 'paragraph',
      data: {
        text:
          'ere may be many tax consequences for investors who hold securities of crowdfunded companies. At the same time, there is no assurance that any money returned to investors will be tax-efficient. Most investors in the companies listed on Prospect Equity can expect to receive returns as corporate dividends and will receive a 1099-DIV shortly after the end of each year, but it is possible that returns may take other forms. Investors should consult their own advisors to determine the relevant tax consequences of investing in these companies. ',
      },
    },
  ],
}
