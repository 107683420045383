import React from 'react'
import T from 'prop-types'
import { cn, getAge } from 'utils'
import { offerDetailsRoute } from 'const/routes'
import { Link } from 'ui/link'
import { ToggleSaveButton } from 'components/toggle-save-button'
import { Golfer } from 'const/types'
import { Progress } from 'ui/progress'

export const Article = ({
  size,
  offering: { image, name, gender, birthday, region, uuid, totalInvestmentAmount, minAmount } = {},
}) => (
  <div className={`tg-card-${size}`}>
    <Link to={offerDetailsRoute({ uuid })} className={`tg-card-${size}-wrap`}>
      <div className={`tg-card-${size}-img`}>
        <img src={image} alt="" srcSet="" className="img-responsive" />
      </div>
      <Progress value={totalInvestmentAmount} max={minAmount} />
    </Link>
    <div className={`tg-card-${size}-info`}>
      <span className="golfer-name">{name} Golf, Inc.</span>
      <div className="tg-card-data clearfix">
        <span className="tg-card-male">
          {gender === 'male' ? 'Male' : 'Female'} {getAge(birthday)}
        </span>
        {region ? <span className="tg-card-based">Based in {region}</span> : false}
        <span className="tg-card-level">{name}</span>
      </div>
    </div>
    <ToggleSaveButton uuid={uuid} className={cn(size === 'big' && 'no-bg')} />
  </div>
)

Article.propTypes = {
  offering: Golfer.isRequired,
  size: T.oneOf(['small', 'big']),
}
Article.defaultProps = {
  size: 'small',
}
