import { post, get, patch, remove, postFormData } from './client'
import URLS from './urls.json'

export const user = {
  getAccount: bind(get, URLS.user.getAccount),
  patchAccount: bind(patch, URLS.user.patchAccount),

  getAddress: bind(get, URLS.user.getAddress),
  postAddress: bind(post, URLS.user.postAddress),
  patchAddress: bind(patch, URLS.user.patchAddress),

  getAchSettings: bind(get, URLS.user.getAchSettings),
  postAchSettings: bind(post, URLS.user.postAchSettings),
  patchAchSettings: bind(patch, URLS.user.patchAchSettings),

  postUpdatePassword: bind(post, URLS.public.forgetPassword),
  postRecoveryPassword: bind(post, URLS.public.forgetPassword),
}

export const image = {
  upload: bind(postFormData, URLS.image),
}

export const investments = {
  getList: bind(get, URLS.investments.getList),
  createInvestmentDraft: bind(post, URLS.investments.create),
  updateInvestmentDraft: id => bind(patch, URLS.investments.update + id),
  postSubscriptionAgreement: bind(post, URLS.investments.createAgreement),
  patchSubscriptionAgreement: id => bind(patch, URLS.investments.createAgreement + '/' + id),
}

export const saved = {
  getList: bind(get, URLS.saved.getList, {
    'pagination[limit]': '-1',
  }),
  addGolfer: id => bind(post, URLS.saved.addGolfer + id + '/mark'),
  deleteGolfer: bind(remove, URLS.saved.deleteGolfer),
}

export const investor = {
  getInvestor: bind(get, URLS.investor.getInvestor),
  updateInvestor: bind(patch, URLS.investor.patchInvestor),
  createInvestor: bind(post, URLS.investor.postInvestor),
  //TODO: insert correct URL
  getActiveInvestorsAmount: bind(get, URLS.investor.activeInvestorsAmount),
}

export const auth = {
  login: bind(post, URLS.auth.login),
  logout: bind(post, URLS.auth.logout),
  refreshToken: bind(post, URLS.auth.refreshToken),
}

export const register = {
  createUser: bind(post, URLS.register.createUser),
}

export const posts = {
  get: bind(get, URLS.posts),
}

export const countries = {
  get: bind(get, URLS.posts),
}

export const offers = {
  get: bind(get, URLS.offers.getList),
  getOffer: id => get(URLS.offers.getOffer + id),
}

export const qa = {
  getList: id => bind(get, URLS.qa.getList + id),
  askQuestion: id => bind(post, URLS.qa.askQuestion + id),
  answerQuestion: id => bind(post, URLS.qa.answerQuestion + id),
}
export const banks = {
  getInfo: id => bind(get, URLS.bankInfo + id),
}

export const wireInstructions = {
  getWire: id => bind(get, URLS.wireInstructions + id + '/wire'),
}

export const notifications = {
  getList: bind(get, URLS.notifications.getList),
  markRead: id => bind(patch, URLS.notifications.markRead + id),
  markAllRead: bind(patch, URLS.notifications.markAllRead),
}

export const contents = {
  getByKey: key => bind(get, `${URLS.contents.get}/${key}`),
  updateByKey: key => bind(patch, `${URLS.contents.update}/${key}`),
  create: bind(post, URLS.contents.create),
}
export const admin = {
  offers: {
    getList: bind(get, URLS.admin.offers.getList),
    getOffer: id => bind(get, URLS.admin.offers.getOffer + id),
  },
  investments: {
    getList: bind(get, URLS.admin.investments.getList),
  },
}

function bind(f, arg) {
  return (...args) => f(arg, ...args)
}
