import './progress.css'
import React from 'react'
import PropTypes from 'prop-types'

import { cn } from 'utils'

export const Progress = ({ value, max, className }) => {
  const width = Math.min(Math.round((value / max) * 100), 100)
  return (
    <div className={cn('progress', className)}>
      <div
        className="progress-value"
        style={{
          width: `${width}%`,
        }}
      />
    </div>
  )
}
Progress.propTypes = {
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}
Progress.defaultProps = {
  value: 0,
  max: 1,
}
