import { createSelector } from 'reselect'
import { ADMIN, SUPER_ADMIN } from 'const/types'

export const rawUser = state => state.user

export const account = createSelector(
  rawUser,
  ({ account }) => account || null,
)

export const fullName = createSelector(
  account,
  account =>
    (account && account.fullName) ||
    (account && [account.firstName, account.lastName].join(' ')) ||
    null,
)

export const avatar = createSelector(
  account,
  account => {
    if (!account || !account.avatarUrl) {
      return null
    }
    return (
      window.location.origin + (account.avatarUrl.startsWith('/') ? '' : '/') + account.avatarUrl
    )
  },
)

export const address = createSelector(
  rawUser,
  ({ address }) => address || null,
)
export const addressUuid = createSelector(
  address,
  address => (address && address.uuid) || null,
)

export const achSettings = createSelector(
  rawUser,
  ({ achSettings }) => achSettings || null,
)

export const achSettingsUuid = createSelector(
  achSettings,
  achSettings => (achSettings && achSettings.uuid) || null,
)

export const isEmailMissing = createSelector(
  account,
  account => (account ? !account.email : false),
)

export const isAgreementSigned = createSelector(
  account,
  account => account && account.isAgreementSigned,
)

export const isAdmin = createSelector(
  account,
  account =>
    account && account.roles && [ADMIN, SUPER_ADMIN].some(role => account.roles.includes(role)),
)

export const hasFaEntity = createSelector(
  account,
  account => !!(account && account.faEntity),
)
