import React from 'react'

import { Link } from 'ui/link'
import { offerDetailsRoute } from 'const/routes'
import { Golfer } from 'const/types'
import { ToggleSaveButton } from 'components/toggle-save-button'
import { Progress } from 'ui/progress'
import { getAge } from 'utils'

export const SearchCard = ({
  offering: { uuid, name, gender, birthday, region, image, totalInvestmentAmount, minAmount },
}) => (
  <div className="col-12 col-lg-6 col-xl-4 mb-40">
    <div className="tg-search-result-card">
      <Link to={offerDetailsRoute({ uuid })} className="tg-search-result-card-img">
        <img src={image} alt="" srcSet="" className="img-responsive" />
        <span className="icon-empty-top" />
      </Link>
      {/*<span className="tg-search-result-card-img">*/}
      {/*  <img src={image} alt="" srcSet="" className="img-responsive" />*/}
      {/*  <span className="icon-empty-top" />*/}
      {/*</span>*/}

      <Progress value={totalInvestmentAmount} max={minAmount} className="mb-20" />

      <div className="tg-search-card--info">
        <span className="golfer-name">{name} Golf Inc.</span>
        <div className="tg-card-data clearfix">
          <span className="tg-card-male">
            {gender === 'male' ? 'Male' : 'Female'} {getAge(birthday)}
          </span>
          {region ? <span className="tg-card-based">Based in {region}</span> : false}
          <span className="tg-card-level">{name}</span>
        </div>
      </div>

      <ToggleSaveButton uuid={uuid} />
    </div>
  </div>
)

SearchCard.propTypes = {
  offering: Golfer.isRequired,
}
