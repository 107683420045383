import React from 'react'
import download from '../../download/formc.pdf'

export const OfferReturnInvest = () => (
  <div className="offer-return-investment offer-inner-block">
    <div className="container">
      <h2 className="mb-50">How will supporters make returns on investment?</h2>
      <p className="mb-20">
        In the first year, where the invested capital pays for all golf-related expenses, <b>65%</b>{' '}
        of any golf-related revenue will be distributed as a dividend to the company’s investors, if
        any golf-related revenue is earned. This potential first-year dividend will also count
        toward the payout multiples below.
      </p>
      <p>Then, over the rest of the golfer’s professional career after the first year:</p>
      <div className="row">
        <div className="col-6 col-md-3">
          <div className="offer-ri-block">
            <div className="offer-ri-scale-wrap text-center">
              <small className="mb-5">1x investment</small>
              <div className="offer-ri-scale bg-blue" />
            </div>
            <div className="offer-ri-block-percent color-blue">30%</div>
            <div className="offer-ri-block-text small">
              of each year’s total golf-related revenue will be distributed by the company as annual
              dividend to investors, if any golf-related revenue is earned. The potential dividend
              will be paid at this rate until an amount equal to the full initial investment is paid
              out.
            </div>
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="offer-ri-block">
            <div className="offer-ri-scale-wrap text-center">
              <small className="mb-5">2x</small>
              <div className="offer-ri-scale bg-blue" />
              <div className="offer-ri-scale" />
            </div>
            <div className="offer-ri-block-percent color-blue">15%</div>
            <div className="offer-ri-block-text small">
              of each year’s total golf-related revenue will be distributed as annual dividends, if
              any golf-related revenue is earned. The potential dividend will be paid at this rate
              until an amount equal to the full initial investment is paid out.
            </div>
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="offer-ri-block">
            <div className="offer-ri-scale-wrap text-center">
              <small className="mb-5">3x</small>
              <div className="offer-ri-scale bg-blue" />
              <div className="offer-ri-scale" />
              <div className="offer-ri-scale" />
            </div>
            <div className="offer-ri-block-percent color-blue">8%</div>
            <div className="offer-ri-block-text small">
              of each year’s total golf-related revenue will be distributed as annual dividends, if
              any golf-related revenue is earned. The potential dividend will be paid at this rate
              until an amount equal to the full initial investment is paid out.
            </div>
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="offer-ri-block">
            <div className="offer-ri-scale-wrap text-center">
              <small className="mb-5">Remainder of golf career</small>
              <div className="offer-ri-scale brd-blue" />
              <div className="offer-ri-scale" />
              <div className="offer-ri-scale" />
              <div className="offer-ri-scale" />
            </div>
            <div className="offer-ri-block-percent color-blue">3%</div>
            <div className="offer-ri-block-text small">
              of each year’s total golf-related revenue after that will be distributed as an annual
              dividend for the remainder of the golfer’s professional career, if any golf-related
              revenue is earned. The potential dividend will be paid at this rate for the remainder
              of the golfer’s career, regardless of length.
            </div>
          </div>
        </div>
      </div>
      <p className="small mb-30">
        *The golfer at the center of this company can choose to end his or her career at any point
        after the initial investment period, even if no revenue has been generated. At the point the
        golfer chooses to end his or her career, the company will have no further dividend
        obligation to investors, regardless of the amount of payout to that point. Your individual
        share of these annual payouts will be equal to the percentage your investment comprises of
        total invested capital in this offering (i.e. the amount invested by all investors at the
        end of the offering, if the goal is successfully met). This dividend payout structure does
        not guarantee or imply that total dividends paid to investors will reach the multiples shown
        above.
      </p>
      <p>
        <a href={download} download className="color-blue">
          {
            'Click here to read this issuer`s statements about its business and the terms of this investment offering, as filed with the SEC'
          }
        </a>
      </p>
    </div>
  </div>
)

OfferReturnInvest.propTypes = {}
