import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { updatePassword } from 'store/user'
import { routes } from 'const/routes'
import { Field } from 'ui/field'
import { Form, SubmitButton, FormControl } from 'ui/form'
import { getParamFromUrl } from 'utils/url'

const validation = ({ password, confirm_password }) => {
  if (!password || !confirm_password) return null
  if (password !== confirm_password) return 'Your password and confirmation password do not match'
}

const PurePasswordReset = ({ redirect, onSubmit }) => {
  return (
    <div className="container password-recovery">
      <div className="auth-block">
        <h2 className="text-center">Please set a new password</h2>
        <div className="auth-form">
          <Form
            onSubmit={onSubmit}
            redirect={redirect}
            className="form single-col mb-40"
            validation={validation}
          >
            <FormControl>
              {({ values }) => {
                return (
                  <>
                    <Field
                      required
                      type="password"
                      name="password"
                      className="mb-60"
                      label="Password"
                      autoComplete="new-password"
                    />
                    <Field
                      required
                      type="password"
                      name="confirm_password"
                      className="mb-60"
                      label="Confirmation password"
                      autoComplete="new-password"
                      validation={() => validation(values)}
                    />
                  </>
                )
              }}
            </FormControl>
            <SubmitButton className="btn w-100">Update Password</SubmitButton>
          </Form>
        </div>
      </div>
    </div>
  )
}

PurePasswordReset.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
}

export const PasswordReset = withRouter(
  connect(
    null,
    (dispatch, { location, history }) => ({
      onSubmit: data => {
        const confirmationToken = getParamFromUrl(location.search, 'confirmationToken')
        return dispatch(updatePassword({ ...data, confirmationToken }))
      },
      redirect: () => {
        history.push(routes.landing)
      },
    }),
  )(PurePasswordReset),
)
