import styles from './field.css'
import React from 'react'
import T from 'prop-types'
import { TextInput } from '../form'
import { ValidationMessage } from '../validation-message'
import { cn } from 'utils'
import { Label } from './Label'

export class Field extends React.PureComponent {
  static propTypes = {
    ...Label.propTypes,
    hideValidationMessage: T.bool,
  }
  static defaultProps = {
    hideValidationMessage: false,
  }
  state = {
    touched: false,
    validationMessage: null,
    enabled: true,
  }
  onValidate = ({ message, enabled, touched = true }) => {
    this.setState({ validationMessage: message, touched, enabled })
  }
  render() {
    const { label, hideValidationMessage, className, children, ...props } = this.props
    const { validationMessage, touched, enabled } = this.state
    const showValidity = !hideValidationMessage && !!validationMessage && enabled
    return (
      <Label
        label={label}
        name={props.name}
        className={cn('field floating-label box-field', className)}
      >
        <TextInput
          {...props}
          placeholder=" "
          className={cn('input', touched && 'touched')}
          onValidate={this.onValidate}
        />
        {children}
        {showValidity && (
          <ValidationMessage className={styles.message} error>
            {validationMessage}
          </ValidationMessage>
        )}
      </Label>
    )
  }
}
