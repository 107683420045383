import { compoundActionType, compoundErrorActionCreator } from '../utils'
import { AUTH } from 'store/auth'
import * as investments from './selectors'

export { investments }

export const INVESTMENTS = {
  CREATE: compoundActionType('investments.create'),
  UPDATE: compoundActionType('investments.commit'),
  GET_LIST: compoundActionType('investments.get-list'),
  ADMIN_GET_LIST: compoundActionType('investments.admin-get-list'),
  ISSUER_GET_LIST: compoundActionType('investments.issuer-get-list'),
  ADMIN_GET_LIST_BY_OFFERING: compoundActionType('investments.admin-get-list-by-offering'),
  CREATE_SUBSCRIPTION_AGREEMENT: compoundActionType('investments.create-subscription-agreement'),
  SIGN_SUBSCRIPTION_AGREEMENT: compoundActionType('investments.sign-subscription-agreement'),
}

export const getInvestmentsList = compoundErrorActionCreator(INVESTMENTS.GET_LIST)
export const getIssuerInvestmentsList = compoundErrorActionCreator(INVESTMENTS.ISSUER_GET_LIST)
export const getAdminList = compoundErrorActionCreator(INVESTMENTS.ADMIN_GET_LIST)
export const getAdminListByOffering = compoundErrorActionCreator(
  INVESTMENTS.ADMIN_GET_LIST_BY_OFFERING,
)
export const createInvestment = compoundErrorActionCreator(INVESTMENTS.CREATE)
export const updateInvestment = compoundErrorActionCreator(INVESTMENTS.UPDATE)
export const createSubscriptionAgreement = compoundErrorActionCreator(
  INVESTMENTS.CREATE_SUBSCRIPTION_AGREEMENT,
)
export const signAgreement = compoundErrorActionCreator(INVESTMENTS.SIGN_SUBSCRIPTION_AGREEMENT)

/**
 * @typedef {Object} Investments
 * @prop {boolean} pending
 * @prop {string|null} error
 */

/** @type {Investments} */
export const initialState = {
  pending: false,
  error: null,
  byId: null,
  agreement: null,
  total: 0,
  listIds: null,
}

/**
 * @param {Countries} state
 * @param {Action} param1
 * @returns {Countries}
 */
export default function(state = initialState, { type, payload }) {
  switch (type) {
    case INVESTMENTS.GET_LIST.START:
    case INVESTMENTS.CREATE.START:
    case INVESTMENTS.UPDATE.START:
    case INVESTMENTS.ISSUER_GET_LIST.START:
    case INVESTMENTS.ADMIN_GET_LIST.START:
    case INVESTMENTS.ADMIN_GET_LIST_BY_OFFERING.START:
      return {
        ...state,
        pending: true,
        error: null,
      }
    case INVESTMENTS.GET_LIST.SUCCESS:
      return {
        ...state,
        pending: false,
        byId: payload
          ? {
              ...(state.byId || {}),
              ...payload,
            }
          : state.byId,
      }
    case INVESTMENTS.ISSUER_GET_LIST.SUCCESS:
    case INVESTMENTS.ADMIN_GET_LIST.SUCCESS:
    case INVESTMENTS.ADMIN_GET_LIST_BY_OFFERING.SUCCESS:
      return payload && payload.items
        ? {
            ...state,
            byId: {
              ...state.byId,
              ...Object.fromEntries(payload.items.map(item => [item.uuid, item])),
            },
            total: payload.pagination.totalItems,
            listIds: payload.items.map(item => item.uuid),
          }
        : state

    case INVESTMENTS.CREATE.SUCCESS:
    case INVESTMENTS.UPDATE.SUCCESS:
      return {
        ...state,
        pending: false,
        byId:
          payload && payload.uuid ? { ...(state.byId || {}), [payload.uuid]: payload } : state.byId,
      }

    case INVESTMENTS.ISSUER_GET_LIST.FAILURE:
    case INVESTMENTS.ADMIN_GET_LIST.FAILURE:
    case INVESTMENTS.ADMIN_GET_LIST_BY_OFFERING.FAILURE:
    case INVESTMENTS.CREATE.FAILURE:
    case INVESTMENTS.UPDATE.FAILURE:
      return {
        ...state,
        pending: false,
        error: payload.error,
      }
    case INVESTMENTS.CREATE_SUBSCRIPTION_AGREEMENT.SUCCESS:
      return {
        ...state,
        agreement: payload,
      }

    case AUTH.LOGOUT.SUCCESS:
      return initialState

    default:
      return state
  }
}

/** @typedef {import('../.').Action} Action */
