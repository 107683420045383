import './styles.css'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { routes } from 'const/routes'
import { recoverPassword } from 'store/password-recovery'
import { Field } from 'ui/field'
import { Form, SubmitButton } from 'ui/form'
import { Link } from 'ui/link'

const PurePasswordRecovery = ({ onSubmit, redirect }) => (
  <div className="container password-recovery">
    <div className="auth-block">
      <h2 className="text-center">Password Recovery</h2>
      <div className="auth-form">
        <span className="descr">Enter the email you used to login to your account.</span>
        <Form onSubmit={onSubmit} redirect={redirect} className="form single-col mb-40">
          <Field type="email" name="email" label="Email" required autoComplete="email username" />
          <SubmitButton className="btn w-100">Send</SubmitButton>
        </Form>
        <div className="text-center mb-60">
          <span>Don&amp;t have an account? </span>
          <Link className="bold color-blue" to={routes.signUp}>
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  </div>
)

PurePasswordRecovery.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
}

export const PasswordRecovery = withRouter(
  connect(
    null,
    (dispatch, { history }) => ({
      onSubmit: data => dispatch(recoverPassword(data)),
      redirect: () => {
        history.push(routes.passwordRecoveryCongrats)
      },
    }),
  )(PurePasswordRecovery),
)
