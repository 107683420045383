import debounce from 'debounce'

const PERSISTANCE_INTERVAL = 500

/**
 * @function
 * @param {{ session:State, local:State }} cfg
 */
export const persist = debounce(persistStates, PERSISTANCE_INTERVAL)

/**
 * @returns {{ session:State, local:State }}
 */
export function restore() {
  return {
    session: read(sessionStorage),
    local: read(localStorage),
  }
}

/**
 * @param {Storage} storage
 * @param {function(State):State} filter
 * @returns {State|{}}
 */
function read(storage) {
  try {
    const state = storage.getItem('state')
    return state ? JSON.parse(state) : {}
  } catch (e) {
    return {}
  }
}

/**
 * @param {Storage} storage
 * @param {State} state
 */
function write(storage, state) {
  try {
    const serializedState = JSON.stringify(state)
    storage.setItem('state', serializedState)
  } catch (err) {
    // Ignore write errors.
  }
}

/**
 * @param {{ session:State, local:State }} cfg
 */
function persistStates(local, session) {
  session && write(sessionStorage, session)
  local && write(localStorage, local)
}
