export const SEARCH_PARAM = 'search'
export const LIMIT_PARAM = 'limit'

export const LIMIT_OPTIONS = {
  15: '15',
  25: '25',
  50: '50',
  100: '100',
}
export const DEFAULT_LIMIT = '15'
export const DEFAULT_PAGE = 1
export const OFFSET_PARAM = 'page'

export const ORDER_BY_PARAM = 'sort'
export const DEFAULT_ORDER = 'asc'

export const NO_ITEMS = 'There are no items yet'
