import { DEFAULT_ORDER, DEFAULT_LIMIT, DEFAULT_PAGE } from 'const/list'

const wrapWithBrackets = x => (typeof x === 'string' ? '[' + x + ']' : '')

export const nameToFilterParam = name =>
  'filter' +
  name
    .split('.')
    .map(wrapWithBrackets)
    .join('')

const isTruthyEntry = ([, value]) => Boolean(value)

const entryToFilterEntry = ([name, value]) => [nameToFilterParam(name), value]

const buildListFilters = params => {
  if (!params) return {}
  return Object.fromEntries(
    Object.entries(params)
      .filter(isTruthyEntry)
      .map(entryToFilterEntry),
  )
}

const buildFieldOrder = ({ orderBy, order = DEFAULT_ORDER } = {}) => {
  if (!orderBy) return {}
  const key =
    'order' +
    orderBy
      .split('.')
      .map(wrapWithBrackets)
      .join('')
  return { [key]: order }
}

const buildPagination = ({ limit = DEFAULT_LIMIT, page = DEFAULT_PAGE } = {}) => ({
  'pagination[limit]': limit,
  'pagination[page]': page,
})

export const buildListParams = ({ limit, orderBy, order, page, search, ...filters } = {}) => {
  return {
    //TODO: search
    ...buildPagination({ limit, page }),
    ...buildFieldOrder({ order, orderBy }),
    ...buildListFilters(filters),
  }
}
