import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Route } from 'react-router-dom'

import { Link } from 'ui/link'
import { routes, offerDetailsQuestionRoute, offerDetailsRoute } from 'const/routes'
import { QuestionAnswerItem } from './QuestionAnswerItem'
import { qa, getQuestionsList, askQuestion } from 'store/qa'
import { Golfer, INVESTOR } from 'const/types'
import { NO_ITEMS } from 'const/list'
import { AskQuestionDialog } from './QADialog'
import { Waitress } from 'components/waitress'
import { Button } from 'ui/button'
import { SignedIn } from 'components/signed'

export const PureQAList = ({ items, getItems, onSubmit, offering, onCloseDialog }) => (
  <Waitress fn={[getItems]}>
    <div className="offer-q-a offer-inner-block">
      <Route
        path={routes.offerDetailsQuestion}
        render={() => (
          <AskQuestionDialog onClose={onCloseDialog} offering={offering} onSubmit={onSubmit} />
        )}
      />
      <div className="container">
        <div className="offer-q-a-head">
          <h2 className="mb-50">Q&amp;A</h2>
          <SignedIn userRole={INVESTOR}>
            <Link
              to={{
                pathname: offerDetailsQuestionRoute({ uuid: offering.uuid }),
                state: { noScroll: true },
              }}
              className="btn ask-question"
            >
              Ask a question
            </Link>
          </SignedIn>
        </div>
        <div className="row mb-40">
          {items && items.length ? (
            items.map(item => (
              <QuestionAnswerItem
                question={item.messages[0]}
                answer={item.messages[1]}
                key={item.uuid}
                createdAt={item.createdAt}
                fullName={item.createdBy.firstName + ' ' + item.createdBy.lastName}
              />
            ))
          ) : (
            <div className="text-center w-100">
              <span>{NO_ITEMS}</span>
            </div>
          )}
        </div>
        <p className="text-center">
          <Button className="color-blue" disabled>
            Load more
          </Button>
        </p>
      </div>
    </div>
  </Waitress>
)

PureQAList.propTypes = {
  getItems: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  offering: Golfer,
  onCloseDialog: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export const QuestionAnswerList = withRouter(
  connect(
    (state, { match, offering }) => {
      const { uuid } = match.params
      return {
        offering,
        items: qa.messagesByOfferingId(state, { uuid }),
      }
    },
    (dispatch, { match, history }) => {
      const { uuid } = match.params
      return {
        getItems: () => dispatch(getQuestionsList({ uuid })),
        onCloseDialog: () =>
          history.push({ pathname: offerDetailsRoute({ uuid }), state: { noScroll: true } }),
        onSubmit: async data => {
          await dispatch(askQuestion({ uuid, ...data }))
          history.push({ pathname: offerDetailsRoute({ uuid }), state: { noScroll: true } })
        },
      }
    },
  )(PureQAList),
)
