import { all } from 'redux-saga/effects'
import { combineReducers, createStore } from 'redux'
import { enhancers, runSideEffects } from './config'
import * as storage from 'utils/storage'

import investments from './investments'
import investmentsSaga from './investments/saga'

import auth from './auth'
import authSaga from './auth/saga'

import user from './user'
import userSaga from './user/saga'

import register from './register'
import registerSaga from './register/saga'

import offers from './offers'
import offersSaga from './offers/saga'

import countries from './countries'
import countriesSaga from './countries/saga'

import saved from './saved'
import savedSaga from './saved/saga'

import states from './states'
import statesSaga from './states/saga'

import investor from './investor'
import investorSaga from './investor/saga'

import qa from './qa'
import qaSaga from './qa/saga'

import notifications from './notifications'
import notificationsSaga from './notifications/saga'

import banks from './banks'
import banksSaga from './banks/saga'

import alerts from './alerts'

import wireInstructions from './wire-instructions'
import wireInstructionsSaga from './wire-instructions/saga'

import contents from './contents'
import contentsSaga from './contents/saga'

import passwordRecovery from './password-recovery'
import passwordRecoverySaga from './password-recovery/saga'

export function* sideEffects() {
  return yield all([
    authSaga(),
    contentsSaga(),
    countriesSaga(),
    investmentsSaga(),
    investorSaga(),
    notificationsSaga(),
    offersSaga(),
    qaSaga(),
    registerSaga(),
    savedSaga(),
    savedSaga(),
    statesSaga(),
    userSaga(),
    banksSaga(),
    wireInstructionsSaga(),
    passwordRecoverySaga(),
  ])
}

/**
 * @returns {Reducer}
 */
export function getRootReducer() {
  return combineReducers({
    alerts,
    auth,
    contents,
    countries,
    investments,
    investor,
    notifications,
    offers,
    qa,
    register,
    saved,
    states,
    user,
    banks,
    wireInstructions,
    passwordRecovery,
  })
}

/**
 * @param {State|{}} state
 * @returns {State|{}} a part of state
 */
export function persistState(state) {
  const {
    auth: { accessToken, refreshToken, expiresAt },
    countries,
    states,
    user: { account, signUpAgreement, address },
    investments: { byOfferingId },
    saved,
    passwordRecovery,
  } = state
  return storage.persist({
    auth: { accessToken, refreshToken, expiresAt },
    countries: { byCode: countries.byCode },
    states: { byCode: states.byCode },
    user: { account, signUpAgreement, address },
    investments: { byOfferingId },
    saved: { byId: saved.byId },
    passwordRecovery: { recoveryMail: passwordRecovery.recoveryMail },
  })
}

function restoreState() {
  const { session, local } = storage.restore()
  return { ...session, ...local }
}

/**
 * @param {Reducer} [rootReducer]
 * @param {function} [saga=sideEffects]
 * @param {object} [initialState={}]
 * @returns {Store}
 */
export default function(
  rootReducer = getRootReducer(),
  saga = sideEffects,
  initialState = restoreState(),
  disablePersistance = false,
) {
  const store = createStore(rootReducer, initialState, enhancers)
  saga && runSideEffects(saga)
  if (!disablePersistance) {
    store.subscribe(() => persistState(store.getState()))
  }

  return store
}

/** @typedef {import('redux').Store} Store */
/** @typedef {import('redux').Reducer} Reducer */
/**
 * @typedef {object} State
 * @prop {import('./auth').Auth} auth
 * @prop {import('./countries').Countries} countries
 */
/**
 * @typedef {object} Action
 * @property {string} type
 * @property {*} payload
 */
