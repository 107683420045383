import { all, select, call, put } from 'redux-saga/effects'

import * as api from 'api'
import { withBearerAuth } from 'api/client'
import { attachSideEffect } from '../utils'
import { AUTH } from '.'
import * as selectors from './selectors'
import { isFutureTimestamp } from 'utils'
import { singletone } from 'utils/promise'

export default function*() {
  yield all([
    attachSideEffect(AUTH.LOGIN, handleLogin),
    attachSideEffect(AUTH.LOGOUT, handleLogout),
  ])
}

/** @param {User} payload */
function* handleLogin(data) {
  // if (!username) throw { error: 'onLogin: missing username' }
  // if (!password) throw { error: 'onLogin: missing password' }
  const result = yield call(api.auth.login, data)
  return result
}

function* handleLogout() {
  const result = yield* callWithAuth(api.auth.logout)
  return result
}

const refreshTokens = singletone(api.auth.refreshToken, args => args.refreshToken)

export function* callWithAuth(apiMethod, data, config = { headers: {} }) {
  let { refreshToken, accessToken, expiresAt } = yield select(selectors.authData) || {}

  if (!refreshToken) {
    yield put({ type: AUTH.REFRESH_TOKEN.FAILURE })
    yield put({ type: AUTH.LOGOUT.SUCCESS })
    return null
  } else if (!expiresAt || !isFutureTimestamp(expiresAt)) {
    yield put({ type: AUTH.REFRESH_TOKEN.START })
    try {
      const refreshResult = yield call(refreshTokens, { refreshToken })
      yield put({ type: AUTH.REFRESH_TOKEN.SUCCESS, payload: refreshResult })
      accessToken = refreshResult.access_token
    } catch (e) {
      yield put({ type: AUTH.REFRESH_TOKEN.FAILURE })
      accessToken = null
    }
  }

  if (!accessToken) {
    yield put({ type: AUTH.LOGOUT.SUCCESS })
    return null
  }

  try {
    const result = yield call(apiMethod, data, withBearerAuth(accessToken, config))
    return result
  } catch (err) {
    if (err && err.unauthorized) {
      yield put({ type: AUTH.LOGOUT.SUCCESS })
    }
    throw err
  }
}

/** @typedef {import('./index').User} User */
