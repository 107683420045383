import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { SearchComponent } from 'components/search-component'
import { FilterDropdown } from 'components/filter-dropdown'
import { Waitress } from 'components/waitress'
import { getStates, selectors as statesSelectors } from 'store/states'
import { Form } from 'ui/form'

const openOptions = {
  true: 'Opened Offerings',
  false: 'Closed Offerings',
}

export const PureSearchOptions = ({ regionOptions, getStates }) => (
  <Waitress fn={[getStates]}>
    <Form className="search-options mb-20" onSubmit={() => {}}>
      <div className="row">
        <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 offset-lg-0 col-lg-4 mb-20">
          <SearchComponent placeholder="Search by name" name="name" />
        </div>

        <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 offset-lg-0 col-lg-4 mb-20 filter-dropdown">
          <FilterDropdown
            name="region"
            searchParam="region"
            label=""
            options={regionOptions}
            placeholder="All Regions"
          />
        </div>
        <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 offset-lg-0 col-lg-4 mb-20 filter-dropdown">
          <FilterDropdown
            name="isOpen"
            searchParam="isOpen"
            label=""
            options={openOptions}
            placeholder="Open/Closed"
          />
        </div>
      </div>
    </Form>
  </Waitress>
)

PureSearchOptions.propTypes = {
  getStates: PropTypes.func.isRequired,
  regionOptions: PropTypes.objectOf(PropTypes.string),
}

export const SearchOptions = connect(
  createStructuredSelector({
    regionOptions: statesSelectors.stateOptions,
  }),
  {
    getStates,
  },
)(PureSearchOptions)
