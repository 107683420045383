import './styles.css'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { SignedOut } from 'components/signed'
import { getUserAccount } from 'store/user'
import { HeroBanner } from './HeroBanner'
import { InvestingInfoBlock } from './InvestingInfoBlock'
import { SignUpBlock } from './SignUpBlock'
import { Search } from './Search'
import { ArticlesList } from './ArticlesList'
import { Link } from 'ui/link'
import { routes } from 'const/routes'

export const LandingPage = connect(
  null,
  {
    getUserAccount,
  },
)(
  class LandingPage extends React.PureComponent {
    static propTypes = {
      getUserAccount: PropTypes.func.isRequired,
    }

    componentDidMount() {
      this.props.getUserAccount()
    }
    render() {
      return (
        <>
          <div className="home">
            <HeroBanner />

            <div className="container">
              <p className="risk">
                Investments on the Prospect Equity platform can carry a high degree of risk, result
                in a complete loss, and be difficult or impossible to sell.{' '}
                <Link to={routes.learnMore}>Read more here</Link>
              </p>
              <ArticlesList title="Ending soon" />
              <Search />

              <InvestingInfoBlock />
            </div>
            <SignedOut>
              <SignUpBlock />
            </SignedOut>
          </div>
        </>
      )
    }
  },
)
