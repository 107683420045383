/* eslint-disable react/no-danger */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { contents, getContentByKey } from 'store/contents'
import { Waitress } from 'components/waitress'
import { ContentBody } from 'const/types'

const Heading = ({ text, level }) =>
  level === 1 ? (
    <h1 dangerouslySetInnerHTML={{ __html: text }} />
  ) : level === 2 ? (
    <h2 dangerouslySetInnerHTML={{ __html: text }} />
  ) : level === 3 ? (
    <h3 dangerouslySetInnerHTML={{ __html: text }} />
  ) : level === 4 ? (
    <h4 dangerouslySetInnerHTML={{ __html: text }} />
  ) : level === 5 ? (
    <h5 dangerouslySetInnerHTML={{ __html: text }} />
  ) : level === 6 ? (
    <h6 dangerouslySetInnerHTML={{ __html: text }} />
  ) : (
    false
  )
Heading.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  text: PropTypes.string,
}

const Paragraph = ({ text }) => <p dangerouslySetInnerHTML={{ __html: text }} />
Paragraph.propTypes = {
  text: PropTypes.string.isRequired,
}
const Components = {
  paragraph: Paragraph,
  header: Heading,
}

export const PureContentViewer = ({ getContent, content, defaultContent }) => {
  const blocks = (content || defaultContent || { blocks: [] }).blocks

  return (
    <Waitress fn={[getContent]}>
      {blocks &&
        blocks.map(({ type, data }, index) => {
          const Component = Components[type]
          // eslint-disable-next-line react/no-array-index-key
          return Component ? <Component key={type + index} {...data} /> : false
        })}
    </Waitress>
  )
}

PureContentViewer.propTypes = {
  content: ContentBody,
  defaultContent: ContentBody,
  getContent: PropTypes.func.isRequired,
}

export const ContentViewer = connect(
  createStructuredSelector({
    content: contents.contentByKey,
  }),
  (dispatch, { contentKey }) => ({
    getContent: () => dispatch(getContentByKey(contentKey)),
  }),
)(PureContentViewer)

ContentViewer.propTypes = {
  contentKey: PropTypes.string.isRequired,
}
