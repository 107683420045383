import './styles.css'
import React from 'react'
import { ContentViewer } from 'components/content-viewer'

export const About = () => {
  return (
    <div className="container static-page about">
      <ContentViewer contentKey="about" defaultContent={DEFAULT} />
    </div>
  )
}

export const DEFAULT = {
  blocks: [
    {
      type: 'header',
      data: {
        level: 3,
        text: 'WHO IS PROSPECT EQUITY?',
        className: 'mb-20',
      },
    },
    {
      type: 'paragraph',
      data: {
        className: 'mb-50',
        text:
          'We&apos;re a New York-based company whose founding team includes Silicon Valley and Wall Street veterans with degrees from schools like MIT and Berkeley, with years of experience in financial securities, venture capital, data science, and technology startups. We&apos;re using all of that accumulated knowledge to build innovative solutions that make it as easy as possible for golfers to raise the funds they need and keep their focus on their performance and career while also helping investors connect with these golfers and find new investment opportunities. Our focus is on making the fundraising experience as straightforward and as satisfying as possible for both golfers and investors.',
      },
    },
    {
      type: 'header',
      data: {
        level: 3,
        text: 'A FOCUS ON HELPING BOTH GOLFERS AND INVESTORS',
        className: 'mb-20',
      },
    },
    {
      type: 'paragraph',
      data: {
        className: 'mb-50',
        text:
          'We&apos;ve spent much of the past year speaking with up-and-coming professional golfers and doing research throughout the pro golf ecosystem. Each and every conversation has helped us to understand the real challenges faced by young golfers early in their careers. Our learning has made it clear that there is a real problem to solve around helping golfers raise the seed funding needed to start their careers, and our backgrounds in the investment world also leads us to believe this can also present a new opportunity for investors. We’re laser-focused on solving these problems and building products in a way that help both golfers and investors achieve their respective goals.',
      },
    },
  ],
}
