import * as api from 'api'
import { all, select } from 'redux-saga/effects'
import { attachSideEffect } from '../utils'
import { INVESTMENTS, investments } from '.'
import { callWithAuth } from 'store/auth/saga'
import { buildListParams } from 'utils/list'

export default function*() {
  yield all([
    attachSideEffect(INVESTMENTS.GET_LIST, onGetInvestmentsList),
    attachSideEffect(INVESTMENTS.ISSUER_GET_LIST, onIssuerGetList),
    attachSideEffect(INVESTMENTS.ADMIN_GET_LIST, onAdminGetList),
    attachSideEffect(INVESTMENTS.ADMIN_GET_LIST_BY_OFFERING, onAdminGetListByOffering),
    attachSideEffect(INVESTMENTS.CREATE, onSaveInvestment),
    attachSideEffect(INVESTMENTS.UPDATE, onSaveInvestment),
    attachSideEffect(INVESTMENTS.CREATE_SUBSCRIPTION_AGREEMENT, onCreateSubscriptionAgreement),
    attachSideEffect(INVESTMENTS.SIGN_SUBSCRIPTION_AGREEMENT, onSignAgreement),
  ])
}

function* onGetInvestmentsList() {
  const result = yield* callWithAuth(api.investments.getList)
  if (!result || !result.items) return {}
  return Object.fromEntries(result.items.map(item => [item.uuid, item]))
}

function* onSaveInvestment(data) {
  const investment = yield select(investments.draftByOfferingId, { uuid: data.offeringUuid })

  const result =
    investment && investment.uuid
      ? yield* callWithAuth(api.investments.updateInvestmentDraft(investment.uuid), data)
      : yield* callWithAuth(api.investments.createInvestmentDraft, data)
  return result
}

function* onCreateSubscriptionAgreement({ uuid }) {
  const investment = yield select(investments.draftByOfferingId, { uuid })
  if (!investment) {
    throw { error: 'Investment not found' }
  }
  const result = yield* callWithAuth(api.investments.postSubscriptionAgreement, {
    investmentUuid: investment.uuid,
  })
  return result
}

function* onSignAgreement(data) {
  const agreement = yield select(investments.agreement)
  if (!agreement) {
    throw { error: 'Agreement not found' }
  }
  const { uuid } = agreement
  const result = yield* callWithAuth(api.investments.patchSubscriptionAgreement(uuid), data)
  return result
}

function* onIssuerGetList({ search, ...params }) {
  const result = yield* callWithAuth(api.investments.getList, {
    ...buildListParams(params),
    ...(search && { 'search[investor][user][fullName]': search }),
  })
  return result
}

function* onAdminGetList(data) {
  const result = yield* callWithAuth(api.admin.investments.getList, buildListParams(data))
  return result
}
function* onAdminGetListByOffering({ uuid, search, ...data }) {
  const result = yield* callWithAuth(api.admin.investments.getList, {
    ...buildListParams({ ...data, 'offering.uuid': uuid }),
    ...(search && { 'search[investor][user][fullName]': search }),
  })
  return result
}
