import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

export const PastTime = ({ date }) => {
  if (date) {
    const endDate = moment.utc(date, null, 'en')
    return <time dateTime={endDate.toISOString()}>{periods(endDate)}</time>
  }
  return false
}

PastTime.propTypes = {
  date: PropTypes.string,
}

export const TimeAgo = ({ date }) => {
  if (date) {
    const endDate = moment.utc(date, null, 'en')
    const isEnd = endDate.isBefore(moment().endOf('second'))
    if (isEnd) return false
    return <time dateTime={endDate.toISOString()}>{periods(endDate)}</time>
  }
  return false
}

TimeAgo.propTypes = {
  date: PropTypes.string,
}

export const OfferingEnds = ({ date }) => {
  const timeAgo = TimeAgo({ date })
  return timeAgo ? <>OFFERING ENDS IN&nbsp; {timeAgo}</> : <>OFFERING ENDED</>
}

OfferingEnds.propTypes = {
  date: PropTypes.string,
}

const periods = endDate => {
  let today = moment.utc()
  const periodsTypes = ['day', 'hour', 'minute']
  let result = []
  for (const period of periodsTypes) {
    const duration = endDate.diff(today, period + 's')

    if (duration) {
      result.push(pluralConstructor(Math.abs(duration), period))
      //if period larger then a week or more then two period types
      if (result.length >= 2 || (period === 'day' && duration > 6)) break
    }
    today = today.add(duration, period + 's')
  }
  return result.join(' ')
}

const pluralConstructor = (duration, name) => `${duration} ${name}${duration === 1 ? '' : 's'}`
