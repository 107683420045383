import { compoundActionType, compoundErrorActionCreator } from '../utils'
import * as offers from './selectors'

export { offers }

export const OFFERS = {
  GET_LIST: compoundActionType('offers.get-list'),
  ADMIN_GET_LIST: compoundActionType('offers.admin.get-list'),
  GET_OFFER: compoundActionType('offers.get-offer'),
  GET_INVESTED_LIST: compoundActionType('offers.get-invested-list'),
  GET_SAVED_LIST: compoundActionType('offers.get-saved-list'),
  SEARCH: compoundActionType('offers.search'),
  SEARCH_MORE: compoundActionType('offers.search-more'),
}

export const getAdminOffersList = compoundErrorActionCreator(OFFERS.ADMIN_GET_LIST)

const getList = compoundErrorActionCreator(OFFERS.GET_LIST)
const getSearchResultsList = compoundErrorActionCreator(OFFERS.SEARCH)

export const getEndingSoonList = () =>
  getList({
    limit: 4,
    orderBy: 'closedAt',
    order: 'asc',
    open: true,
  })

export const search = params => getSearchResultsList(params)

export const getOffer = compoundErrorActionCreator(OFFERS.GET_OFFER)

export const getSavedOfferingsList = compoundErrorActionCreator(OFFERS.GET_SAVED_LIST)
export const getInvestedOfferingsList = compoundErrorActionCreator(OFFERS.GET_INVESTED_LIST)
export const getMoreSearchResults = compoundErrorActionCreator(OFFERS.SEARCH_MORE)

export const initialState = {
  byId: {},
  pending: false,
  error: null,
  isSearching: false,
  searchParams: {},
  searchResultsTotal: 0,
  searchResultIds: [],

  closingSoonIds: [],
  listItemIds: [],
  listItemsTotal: 0,
}

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case OFFERS.ADMIN_GET_LIST.START:
    case OFFERS.GET_LIST.START:
    case OFFERS.GET_OFFER.START:
      return {
        ...state,
        pending: true,
        error: null,
      }
    case OFFERS.GET_OFFER.SUCCESS:
      return {
        ...state,
        pending: false,
        byId: payload && { ...state.byId, ...payload },
      }
    case OFFERS.ADMIN_GET_LIST.SUCCESS:
      return {
        ...state,
        pending: false,
        byId: payload && payload.items ? { ...state.byId, ...payload.items } : state.byId,
        listItemsTotal: (payload && payload.total) || 0,
        listItemIds: (payload && payload.items && Object.keys(payload.items)) || null,
      }
    case OFFERS.GET_LIST.SUCCESS:
      return {
        ...state,
        pending: false,
        byId: payload && payload.items ? { ...state.byId, ...payload.items } : state.byId,
        closingSoonIds: (payload.items && Object.keys(payload.items)) || null,
      }
    case OFFERS.GET_OFFER.FAILURE:
    case OFFERS.GET_LIST.FAILURE:
    case OFFERS.ADMIN_GET_LIST.FAILURE:
      return {
        ...state,
        pending: false,
        error: payload.error,
      }
    case OFFERS.SEARCH.START:
      return {
        ...state,
        isSearching: true,
        searchParams: payload || {},
      }
    case OFFERS.SEARCH.SUCCESS:
      return {
        ...state,
        isSearching: false,
        byId: payload.items ? { ...state.byId, ...payload.items } : state.byId,
        searchResultIds: payload && payload.items ? Object.keys(payload.items) : [],
        searchResultsTotal: (payload && payload.total) || 0,
      }
    case OFFERS.SEARCH.FAILURE:
    case OFFERS.SEARCH_MORE.FAILURE:
      return {
        ...state,
        isSearching: false,
      }
    case OFFERS.SEARCH_MORE.START:
      return {
        ...state,
        isSearching: true,
      }
    case OFFERS.SEARCH_MORE.SUCCESS:
      return {
        ...state,
        isSearching: false,
        byId: payload && payload.items ? { ...state.byId, ...payload.items } : state.byId,
        searchResultIds:
          payload && payload.items
            ? [...state.searchResultIds, ...Object.keys(payload.items)]
            : state.searchResultIds,
      }
    case OFFERS.GET_SAVED_LIST.SUCCESS:
    case OFFERS.GET_INVESTED_LIST.SUCCESS:
      return {
        ...state,
        isSearching: false,
        byId: payload && payload.items ? { ...state.byId, ...payload.items } : state.byId,
      }

    default:
      return state
  }
}
