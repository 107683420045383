import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'ui/button'

export const ShareGolferButton = ({ onClick, children }) => (
  <Button className="no-bg" onClick={onClick}>
    {children}
  </Button>
)

ShareGolferButton.propTypes = {
  onClick: PropTypes.func,
}
