import './styles.css'
import React, { useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { SubmitButton } from 'ui/form'
import { Button } from 'ui/button'
import { CopiedField } from 'ui/field/CopiedField'
import { Dialog } from 'ui/dialog'
import { GolferInfo } from '../golfer-info'
import { Golfer } from 'const/types'
import { offerDetailsRoute } from 'const/routes'
import { iosCopy, isIOS } from 'utils'

const CloseButtons = ['Esc', 'Escape', 'Enter']

export const PureShareGolfer = ({ onClose, offering = {} }) => {
  const [isCopied, setCopied] = useState(false)
  const inputRef = useRef(null)
  const { uuid } = offering
  const isShareEnable = window.navigator.share
  const origin = window.location.origin

  const copyLink = () => {
    if (isIOS()) {
      iosCopy(inputRef.current)
      return setCopied(true)
    }
    inputRef.current.select()
    inputRef.current.focus()
    try {
      navigator.clipboard.writeText(origin + offerDetailsRoute({ uuid }))
    } catch (e) {
      document.execCommand('copy')
    }
    setCopied(true)
  }
  const share = () => {
    window.navigator.share({
      title: document.title,
      text: 'Share Link',
      url: origin + offerDetailsRoute({ uuid }),
    })
  }
  const closePopup = e => {
    const target = e.target
    if (target.className === 'dialog-bg' || target.className === 'share-dialog') onClose()
  }
  const handleKeyPress = e => {
    const { key } = e
    if (CloseButtons.includes(key)) {
      closePopup(e)
    }
  }
  return (
    <div
      className="share-dialog"
      tabIndex="0"
      role="button"
      onClick={closePopup}
      onKeyUp={handleKeyPress}
    >
      <Dialog onClose={onClose} title="Share Link">
        <GolferInfo golfer={offering} />
        <CopiedField
          name="question"
          className="mb-30"
          inputRef={inputRef}
          defaultValue={origin + offerDetailsRoute({ uuid })}
          readOnly
        />
        <div className="question-footer">
          <div />
          <div className="button-wrapper">
            <SubmitButton onClick={copyLink}>{isCopied ? 'Copied!' : 'Copy Link'}</SubmitButton>
            {isShareEnable && <Button onClick={share}>Share</Button>}
          </div>
        </div>
      </Dialog>
    </div>
  )
}

PureShareGolfer.propTypes = {
  offering: Golfer.isRequired,
  onClose: PropTypes.func.isRequired,
}

export const ShareGolfer = withRouter(PureShareGolfer)
