import pathToRegexp from 'path-to-regexp'

/** @enum */
export const routes = {
  landing: '/',

  // public
  offerDetails: '/offers/:uuid',
  offerDetailsQuestion: '/offers/:uuid/question',
  about: '/about',
  learnMore: '/learn-more',
  privacyPolicy: '/privacy-policy',
  termsOfUse: '/terms-of-use',

  // user
  login: '/login',

  // sign up
  signUp: '/signup',
  signUpAgreement: '/signup/agreement',
  signUpGoogle: '/signup/complete/google',
  signUpFacebook: '/signup/complete/facebook',

  // role = ADMIN + SUPERADMIN
  admin: '/admin',
  adminLanding: '/admin/landing',
  adminAbout: '/admin/about',
  adminLearnMore: '/admin/learn-more',
  adminTermsOfUse: '/admin/terms-of-use',
  adminPrivacyPolicy: '/admin/privacy-policy',
  adminIssuersList: '/admin/issuers-list',
  adminIssuerDetails: '/admin/issuers/:uuid',
  adminIssuerDetailsProfile: '/admin/issuers/:uuid/profile',
  adminIssuerDetailsInvestments: '/admin/issuers/:uuid/investments',
  adminIssuerDetailsDividends: '/admin/issuers/:uuid/dividends',
  adminInvestorsList: '/admin/investors',
  adminInvestorDetails: '/admin/investors/:uuid',
  adminUsersList: '/admin/admin-users',
  adminEmailsList: '/admin/emails',

  // role = INVESTOR
  investor: '/investor',
  settings: '/investor/settings',
  settingsAccount: '/investor/settings/account',
  settingsProfile: '/investor/settings/profile',
  settingsPayment: '/investor/settings/payment',
  favorites: '/investor/favorites',
  notifications: '/investor/notifications',
  // investments: '/investor/investments',
  addInvestment: '/investor/invest/:uuid',
  addInvestmentStep0: '/investor/invest/:uuid/0',
  addInvestmentStep1: '/investor/invest/:uuid/1',
  addInvestmentStep2: '/investor/invest/:uuid/2',
  addInvestmentStep3: '/investor/invest/:uuid/3',
  addInvestmentStep3Ach: '/investor/invest/:uuid/3/ach',
  addInvestmentStep3Wire: '/investor/invest/:uuid/3/wire',
  addInvestmentStep4: '/investor/invest/:uuid/4',
  myInvestments: '/investor/my-investments',
  myInvestmentCommitted: '/investor/my-investments/committed',
  myInvestmentInvested: '/investor/my-investments/invested',
  myInvestmentSaved: '/investor/my-investments/saved',
  myInvestmentWireInstruction: '/investor/my-investments/committed/:uuid/wire',

  // role = ISSUER
  issuer: '/issuer',
  issuerQuestionAnswer: '/issuer/question-answer',
  issuerInvestments: '/issuer/investments',

  passwordRecovery: '/password-recovery',
  passwordRecoveryCongrats: '/password-recovery/congrats',
  passwordReset: '/password-reset',
}

export const offerDetailsRoute = pathToRegexp.compile(routes.offerDetails)
export const offerDetailsQuestionRoute = pathToRegexp.compile(routes.offerDetailsQuestion)
export const addInvestmentRoute = pathToRegexp.compile(routes.addInvestment)
export const addInvestmentStep0Route = pathToRegexp.compile(routes.addInvestmentStep0)
export const addInvestmentStep1Route = pathToRegexp.compile(routes.addInvestmentStep1)
export const addInvestmentStep2Route = pathToRegexp.compile(routes.addInvestmentStep2)
export const addInvestmentStep3Route = pathToRegexp.compile(routes.addInvestmentStep3)
export const addInvestmentStep3AchRoute = pathToRegexp.compile(routes.addInvestmentStep3Ach)
export const addInvestmentStep3WireRoute = pathToRegexp.compile(routes.addInvestmentStep3Wire)
export const addInvestmentStep4Route = pathToRegexp.compile(routes.addInvestmentStep4)
export const myInvestmentWireInstructionRoute = pathToRegexp.compile(
  routes.myInvestmentWireInstruction,
)

export const adminIssuerDetailsRoute = pathToRegexp.compile(routes.adminIssuerDetails)
export const adminIssuerDetailsProfileRoute = pathToRegexp.compile(routes.adminIssuerDetailsProfile)
export const adminIssuerDetailsInvestmentsRoute = pathToRegexp.compile(
  routes.adminIssuerDetailsInvestments,
)
export const adminIssuerDetailsDividentsRoute = pathToRegexp.compile(
  routes.adminIssuerDetailsDividends,
)

export const adminInvestorDetailsRoute = pathToRegexp.compile(routes.adminInvestorDetails)
