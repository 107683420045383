import './hero-banner.css'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { Link } from 'ui/link'
import { routes } from 'const/routes'
import { SignedOut } from 'components/signed'
import bgImage from 'img/main-banner1.png'
import { ContentViewer } from 'components/content-viewer'
import { getLandingHeroContent, contents } from 'store/contents'
import { Waitress } from 'components/waitress'

export const PureHeroBanner = ({ getLandingHeroContent, landingHeroContent }) => (
  <Waitress fn={[getLandingHeroContent]}>
    <div className="hero-banner">
      <img
        src={(landingHeroContent && landingHeroContent.backgroundImageSrc) || bgImage}
        className="main-banner-img"
        alt=""
      />
      <div className="main-banner-text container">
        <div className="row">
          <div className="col-12 col-md-9 col-lg-7">
            <ContentViewer className="col-12 col-md-9 col-lg-7" contentKey="landing/hero" />
          </div>
        </div>

        <SignedOut>
          <Link to={routes.signUp} theme="primary">
            Sign up to invest
          </Link>
        </SignedOut>
      </div>
    </div>
  </Waitress>
)

PureHeroBanner.propTypes = {
  getLandingHeroContent: PropTypes.func.isRequired,
  landingHeroContent: PropTypes.shape({
    backgroundImageSrc: PropTypes.string,
  }),
}

export const HeroBanner = connect(
  createStructuredSelector({
    landingHeroContent: contents.landingHeroContent,
  }),
  {
    getLandingHeroContent,
  },
)(PureHeroBanner)
