import './dialog.css'
import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'ui/button'

// https://developer.mozilla.org/ru/docs/Web/HTML/Element/dialog

export class Dialog extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
  }
  componentDidMount() {
    document.body.classList.add('noScroll')
  }

  componentWillUnmount() {
    document.body.classList.remove('noScroll')
  }

  render() {
    const { children, onClose, title } = this.props
    return (
      <div className="dialog-bg">
        <dialog open className="dialog">
          <Button className="close float-right" onClick={onClose}>
            <span>&times;</span>
          </Button>
          <h5 className="mb-25">{title}</h5>
          {children}
        </dialog>
      </div>
    )
  }
}
