import React from 'react'
import T from 'prop-types'

export const Money = ({ currency, amount, locales, ...props }) => {
  const formatter = new Intl.NumberFormat(locales, {
    currency,
    style: 'currency',
  })

  return <span {...props}>{formatter.format(amount || 0)}</span>
}

Money.propTypes = {
  amount: T.oneOfType([T.number, T.string]),
  currency: T.string.isRequired,
  locales: T.string.isRequired,
}

Money.defaultProps = {
  currency: 'USD',
  amount: 0,
  locales: 'en-US', // window.navigator.languages ???
}
