import styles from './field.css'
import React from 'react'
import T from 'prop-types'
import { ValidationMessage } from '../validation-message'
import { cn } from 'utils'
import { Label } from './Label'

export class CopiedField extends React.PureComponent {
  static propTypes = {
    ...Label.propTypes,
    hideValidationMessage: T.bool,
  }
  static defaultProps = {
    hideValidationMessage: false,
  }
  state = {
    touched: false,
    validationMessage: null,
    enabled: true,
  }
  onValidate = ({ message, valid, name, enabled }) => {
    this.setState({ validationMessage: message, touched: true, enabled }, () => {
      this.props.onValidate && this.props.onValidate({ message, valid, name, enabled })
    })
  }
  render() {
    const { label, hideValidationMessage, className, children, inputRef, ...props } = this.props
    const { validationMessage, touched, enabled } = this.state
    const showValidity = !this.props.hideValidationMessage && !!validationMessage && enabled
    return (
      <Label
        label={label}
        name={props.name}
        className={cn('field floating-label box-field', className)}
      >
        <input
          ref={inputRef}
          {...props}
          placeholder=" "
          className={cn('input', touched && 'touched')}
        />
        {children}
        {showValidity && (
          <ValidationMessage className={styles.message} error>
            {validationMessage}
          </ValidationMessage>
        )}
      </Label>
    )
  }
}
