import './details.css'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { getOffer, offers } from 'store/offers'
import { RouteProps, Golfer } from 'const/types'
import { addInvestmentRoute } from 'const/routes'
import { Tooltip } from 'ui/tooltip'
import { Link } from 'ui/link'
import { OfferBanner } from './OfferBanner'
import { OfferReturnInvest } from './OfferReturnInvest'
import { QuestionAnswerList } from './QuestionAnswerList'
import { Waitress } from 'components/waitress'
import { Progress } from 'ui/progress'
import { TimeAgo } from 'ui/time-ago'
import { Money } from 'ui/money'
import { ShareGolferButton } from 'components/share-golfer-button'
import { ShareGolfer } from 'components/share-golfer-component/ShareGolfer'
import { cn, isPast } from 'utils'

const ACCOMPLISHMENTS = [
  [true, 2019, 'PGA Canada Q-School', '23rd place'],
  [false, 2019, 'World Golf Ranking #1542'],
  [false, 2018, 'Outlaw Tour', '12th on season money list'],
  [false, null, 'No 1 Outlaw Tour tournament win', '3 top 10’s'],
  [true, 2018, 'Outlaw Tour Southern Dunes Open', '2nd place'],
  [true, 2018, 'Colorado Open', '9th place'],
  [true, 2018, 'PGA Canada Windsor Open', '15nd place'],
  [false, 2017, 'NCAA Division 2 - All-America', '2nd team'],
  [false, 2018, 'World Amateur Golf Ranking', '#410'],
  [false, 2017, '4 year member of varsity team, 80 collegiate starts (2013-2017)'],
  [true, 2017, 'NCAA Division 2 Championship', '19th place'],
  [true, 2017, 'Peach Belt Conference Championship', '3rd place'],
  [false, 2017, '2 collegiate tournament wins', '5 top 5’s'],
  [false, 2013, 'AJGA All-American Honorable Mention'],
  [false, 2013, '2 time Ohio All-State, 3 time All-Conference selection (2010-2013)'],
  [false, 2012, 'US Junior Amateur', '31st place'],
]

const TOURNAMENT_FEES = [
  ['August 9-12', 'Adams Tour Buffalo Run Casino Classic', '$1,400'],
  ['August 18', ' Web.com Rex Hospital Open Qualifier', '$450'],
  ['August 21-24', 'St. Louis Metropolitan Open', '$1,000'],
  ['September 6-8', 'Web.com Q-School Pre-qualifier', '$2700'],
  ['September 23-25', '97th Davey Tree Ohio Open Championship', '$395'],
  ['October 10-13', 'Web.com Q-School 1st Stage', '$2500'],
  ['October 25-29', 'TrueNorth Greater Cedar Rapids Open', '$700'],
  ['November 7-10', 'Web.com Q-School 2nd Stage', 'No fee'],
]
const OTHER_FEES = [
  ['Airfare, food, and lodging - Buffalo Run Casino Classic', '$850'],
  ['Gas, food, and lodging - Rex Hospital Open', '$400'],
  ['Airfare, food, and lodging - Q-School Pre-qualifier', '$1050'],
  ['Gas, food, and lodging — Davey Tree Open', '$250'],
  ['Airfare, food, and lodging — Q-School 1st Stage', '$1500'],
  ['Airfare, food, and lodging — TrueNorth Greater Cedar Rapids Open', '$750'],
  ['Airfare, food, and lodging — Q-School 2nd Stage', '$1200'],
  ['Greens fees for practice time (entire season', '$1500'],
  ['Coaching', '$2000'],
]

export const PureOfferDetailsPage = ({
  golfer,
  match: {
    params: { uuid },
  },
  getOffer,
  accomplishments,
  tournamentFees,
  otherFees,
}) => {
  const [isShareOpen, shareToggle] = useState(false)

  const {
    firstName,
    description,
    totalInvestmentAmount,
    minAmount,
    maxAmount,
    closedAt,
    investorNumber,
  } = golfer || {}

  const isClosed = isPast(closedAt)

  return (
    <Waitress fn={[getOffer]}>
      <div className="offer-details">
        <OfferBanner golfer={golfer} />
        <div className="offer-top-block">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8 offer-top-text-row">
                <div className="offer-top-text">
                  <span className="total-sum">
                    <Money amount={totalInvestmentAmount} />
                  </span>
                  &nbsp;raised of &nbsp;
                  <Money amount={minAmount} /> &nbsp;- &nbsp; <Money amount={maxAmount} /> &nbsp;
                  goal {pluralConstructor(investorNumber)}
                  <Tooltip tooltip="This fundraising round will close and investor funds will be invested in the company if and when either a) the maximum investment amount is reached or b) the close date of the fundraising round is reached and the minimum investment amount has been reached. Please review the SEC Form C linked below for more detail" />
                </div>
              </div>
              <div className="col-6 offset-md-2 col-md-4 offset-lg-0 col-lg-2">
                <Link
                  to={addInvestmentRoute({ uuid })}
                  className={cn('btn btn-primary w-100', isClosed && 'disabled')}
                >
                  Invest
                </Link>
              </div>
              <div className="col-6 col-md-4 col-lg-2 ">
                <ShareGolferButton uuid={uuid} onClick={() => shareToggle(true)}>
                  Share
                </ShareGolferButton>
              </div>
            </div>
          </div>
        </div>
        {isShareOpen && <ShareGolfer offering={golfer} onClose={() => shareToggle(false)} />}
        <div className="offer-goal">
          <Progress value={totalInvestmentAmount} max={maxAmount} />
        </div>
        <div className="offer-about-experience">
          <div className="grey" />
          <div className="box offer-details-about">
            <h2 className="mb-50">About {firstName}</h2>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          <div className="box offer-details-experience">
            <h2 className="mb-50">{firstName}’s experience &amp; accomplishments</h2>
            <table className="offer-progress-table">
              <tbody>
                {accomplishments.map(([trophy, year, champ, result]) => (
                  <tr className={cn(trophy && 'win')} key={champ + year}>
                    <td>
                      <span className="win-label" />
                    </td>
                    <td>{year}</td>
                    <td>
                      {champ} {result}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="images">
            <div className="offer-img">
              <img src="https://via.placeholder.com/545x320" className="img-responsive" alt="" />
            </div>
            <div className="offer-img">
              <img src="https://via.placeholder.com/545x320" className="img-responsive" alt="" />
            </div>
            <div className="offer-img">
              <img src="https://via.placeholder.com/545x320" className="img-responsive" alt="" />
            </div>
          </div>
        </div>
        <div className="offer-inner-block">
          <div className="container offer-use-investment">
            <h2 className="mb-50">How will the investment be used?</h2>
            <div className="row">
              <div className="col-12 col-lg-6 mb-30">
                <table className="investments-usage">
                  <caption>Tournament fees</caption>
                  <tbody>
                    {tournamentFees.map(([dates, description, price]) => (
                      <tr key={description}>
                        <td>{dates}</td>
                        <td>{description}</td>
                        <td>{price}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="col-12 col-lg-6">
                <table className="investments-usage">
                  <caption>Other (e.g. transportation, lodging, coaching)</caption>
                  <tbody>
                    {otherFees.map(([description, price]) => (
                      <tr key={description}>
                        <td>{description}</td>
                        <td>{price}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <OfferReturnInvest />
        <QuestionAnswerList offering={golfer} />
        {!isClosed && (
          <div className="offer-invest offer-inner-block text-center">
            <h3>Invest now in {firstName}’s and your future.</h3>
            <div className="mb-20">
              <small>
                <TimeAgo date={closedAt} /> left
              </small>
            </div>
            <Link to={addInvestmentRoute({ uuid })} theme="primary">
              Invest
            </Link>
          </div>
        )}
      </div>
    </Waitress>
  )
}

PureOfferDetailsPage.propTypes = {
  accomplishments: PropTypes.arrayOf(PropTypes.array),
  getOffer: PropTypes.func.isRequired,
  golfer: Golfer,
  match: RouteProps.match,
  otherFees: PropTypes.arrayOf(PropTypes.array),
  tournamentFees: PropTypes.arrayOf(PropTypes.array),
}

export const OfferDetailsPage = withRouter(
  connect(
    (state, { match }) => {
      const { uuid } = match.params
      return {
        golfer: offers.golfer(state, { uuid }),
        accomplishments: ACCOMPLISHMENTS,
        tournamentFees: TOURNAMENT_FEES,
        otherFees: OTHER_FEES,
      }
    },
    (dispatch, { match }) => ({
      getOffer: () => dispatch(getOffer(match.params.uuid)),
    }),
  )(PureOfferDetailsPage),
)

const pluralConstructor = investorNumber => {
  switch (investorNumber) {
    case 0:
      return
    case 1:
      return (
        <span className="investor-counter">
          &nbsp; by <b>{investorNumber}</b> investor
        </span>
      )
    default:
      return (
        <span className="investor-counter">
          &nbsp; by <b>{investorNumber}</b> investors
        </span>
      )
  }
}
