import { createSelector } from 'reselect'
import { saved } from 'store/saved'
import { investments } from 'store/investments'

export const pending = state => state.offers.pending
export const error = state => state.offers.error

export const offersMap = state => {
  return state.offers.byId && Object.keys(state.offers.byId).length > 0 ? state.offers.byId : null
}

const listItemIds = state => state.offers.listItemIds || null
const closingSoonIds = state => state.offers.closingSoonIds || null
const searchResultIds = state => state.offers.searchResultIds || null

export const offersList = createSelector(
  offersMap,
  listItemIds,
  (offers, ids) => getValuesByPropNames(offers, ids),
)

export const closingSoonList = createSelector(
  offersMap,
  closingSoonIds,
  (offers, ids) => getValuesByPropNames(offers, ids),
)

export const searchResults = createSelector(
  offersMap,
  searchResultIds,
  (offers, ids) => getValuesByPropNames(offers, ids),
)

export const totalItems = state => state.offers.listItemsTotal

export const searchResultsAmount = state => state.offers.searchResultsTotal || 0

export const hasMoreItems = createSelector(
  searchResultsAmount,
  searchResults,
  (searchResultsAmount, searchResults) =>
    ((searchResults && searchResults.length) || 0) < searchResultsAmount,
)

export const isSearching = state => state.offers.isSearching

export const golfer = (state, { uuid } = {}) =>
  (state.offers.byId && state.offers.byId[uuid]) || null

export const savedList = createSelector(
  saved.savedOfferingIds,
  offersMap,
  saved.byId,
  (ids, offersMap, savedById) => {
    const result = offersMap && ids && ids.filter(id => !!offersMap[id]).map(id => offersMap[id])
    if (!result || !result.length) return null
    return result.map(item => ({
      offering: item,
      saved: savedById[item.uuid],
    }))
  },
)

export const missingSavedIds = createSelector(
  saved.savedOfferingIds,
  offersMap,
  (ids, offersMap) => {
    if (!ids || !ids.length) return []
    return offersMap ? ids.filter(id => !offersMap[id]) : ids
  },
)

export const committedList = createSelector(
  investments.all,
  offersMap,
  (investmentsList, offersMap) => {
    if (!investmentsList || !offersMap) return null
    return investmentsList
      .filter(investment => !!offersMap[investment.offeringUuid] && investment.status !== 'draft')
      .map(investment => ({
        offering: offersMap[investment.offeringUuid],
        investment,
      }))
      .sort((a, b) => new Date(b.investment.updatedAt) - new Date(a.investment.updatedAt))
  },
)

// eslint-disable-next-line no-undef
const imagePaths = require
  .context('img/mock_data/', false, /\.*$/)
  .keys()
  .map(name => name.replace('./', '/assets/img/mock_data/'))

export const investedList = () => [
  {
    offering: {
      uuid: '58641a05-7214-4f5f-9771-bc764895d451',
      name: 'Elden Liven',
      state: 'Nevada',
      gender: 'Male',
      totalInvestmentAmount: 9077,
      minAmount: 5705,
      isGoalMet: true,
      image: imagePaths[4],
    },
    investment: {
      offeringUuid: 'z',
      uuid: '06c93571-afa0-4dfa-9aad-5fb3831fd3fb',
      createdAt: '2019-01-02T18:29:38+00:00',
      amount: 3000,
    },
  },
  {
    offering: {
      uuid: '06c93571-afa0-4dfa-9aad-5fb3831fd3fb',
      name: 'Lawton Stockton',
      state: 'Minnesota',
      gender: 'Male',
      totalInvestmentAmount: 3814,
      minAmount: 3286,
      isGoalMet: true,
      image: imagePaths[5],
    },
    investment: {
      offeringUuid: 'z',
      uuid: '06c93571-afa0-4dfa-9aad-5fb3831fd3fb',
      createdAt: '2019-04-22T18:29:38+00:00',
      amount: 4000,
    },
  },
  {
    offering: {
      uuid: '22961132-c792-4211-9555-f968a3d8ba73',
      name: 'Dwight Heinrici',
      state: 'Florida',
      gender: 'Male',
      totalInvestmentAmount: 5365,
      minAmount: 1842,
      isGoalMet: true,
      image: imagePaths[6],
    },
    investment: {
      offeringUuid: 'z',
      uuid: '06c93571-afa0-4dfa-9aad-5fb3831fd3fb',
      createdAt: '2019-03-22T18:29:38+00:00',
      amount: 200,
    },
  },
  {
    offering: {
      uuid: '2bf1a5aa-dc4b-4096-92c1-520726324d02',
      name: 'Sampson Bensusan',
      state: 'Oregon',
      gender: 'Male',
      totalInvestmentAmount: 1890,
      minAmount: 3755,
      isGoalMet: false,
      image: imagePaths[7],
    },
    investment: {
      offeringUuid: 'z',
      uuid: '06c93571-afa0-4dfa-9aad-5fb3831fd3fb',
      createdAt: '2019-01-22T18:29:38+00:00',
      amount: 500,
    },
  },
]

export const missingInvestedIds = createSelector(
  investments.commitedOfferingIds,
  offersMap,
  (ids, offersMap) => {
    if (!ids) return null
    if (!offersMap) return ids
    const missingIds = ids.filter(id => !offersMap[id])
    return missingIds.length ? missingIds : null
  },
)

const getValuesByPropNames = (object, keys) =>
  object && Array.isArray(keys) ? keys.map(key => object[key]).filter(Boolean) : []
