import { AUTH } from 'store/auth'

import * as user from './selectors'
import { compoundActionType, compoundErrorActionCreator } from 'store/utils'

export { user }
//TODO Delete UPDATE_QUESTION_ANSWERS after connecting messages to own endpoint
export const USER = {
  GET_ACCOUNT: compoundActionType('user.get-account'),
  PATCH_ACCOUNT: compoundActionType('user.patch-account'),
  GET_ADDRESS: compoundActionType('user.get-address'),
  UPDATE_ADDRESS: compoundActionType('user.patch-address'),
  GET_ACH_SETTINGS: compoundActionType('user.get-ach-settings'),
  UPDATE_ACH_SETTINGS: compoundActionType('user.patch-ach-settings'),
  UPDATE: compoundActionType('user.update'),
  SIGN_AGREEMENT: compoundActionType('user.sign-agreement'),
  UPDATE_PROFILE_IMAGE: compoundActionType('user.update-profile-image'),
  UPDATE_QUESTION_ANSWERS: compoundActionType('user.update-question-answers'),
  UPDATE_PASSWORD: compoundActionType('user.update-password'),
}

export const getUserAccount = compoundErrorActionCreator(USER.GET_ACCOUNT)
export const patchUserAccount = compoundErrorActionCreator(USER.PATCH_ACCOUNT)
export const getUserAddress = compoundErrorActionCreator(USER.GET_ADDRESS)
export const updateUserAddress = compoundErrorActionCreator(USER.UPDATE_ADDRESS)
export const getUserAchSettings = compoundErrorActionCreator(USER.GET_ACH_SETTINGS)
export const updateUserAchSettings = compoundErrorActionCreator(USER.UPDATE_ACH_SETTINGS)
export const signAgreement = compoundErrorActionCreator(USER.SIGN_AGREEMENT)
export const updateUser = compoundErrorActionCreator(USER.UPDATE)
export const updateProfileImage = compoundErrorActionCreator(USER.UPDATE_PROFILE_IMAGE)
export const updateQuestionAnswers = compoundErrorActionCreator(USER.UPDATE_QUESTION_ANSWERS)
export const updatePassword = compoundErrorActionCreator(USER.UPDATE_PASSWORD)

export const initialState = {
  error: null,
  pending: false,
  account: null,
  address: null,
  achSettings: null,
}

export default function userReducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    case USER.GET_ACCOUNT.START:
    case USER.PATCH_ACCOUNT.START:
    case USER.GET_ADDRESS.START:
    case USER.UPDATE.START:
    case USER.UPDATE_ADDRESS.START:
    case USER.GET_ACH_SETTINGS.START:
    case USER.UPDATE_ACH_SETTINGS.START:
    case USER.SIGN_AGREEMENT.START:
    case USER.UPDATE_QUESTION_ANSWERS.START:
    case USER.UPDATE_PASSWORD.START:
      return {
        ...state,
        pending: true,
        error: null,
      }
    case USER.UPDATE_PASSWORD.SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
      }
    case USER.UPDATE_QUESTION_ANSWERS.SUCCESS:
      return {
        ...state,
        account: {
          ...state.account,
          questionAndAnswer: state.account.questionAndAnswer.map(message =>
            message.uuid === payload.uuid ? payload : message,
          ),
        },
        pending: false,
      }
    case USER.GET_ACCOUNT.SUCCESS:
    case USER.PATCH_ACCOUNT.SUCCESS:
      return {
        ...state,
        pending: false,
        account: payload || null,
      }
    case USER.GET_ADDRESS.SUCCESS:
    case USER.UPDATE_ADDRESS.SUCCESS:
      return {
        ...state,
        pending: false,
        address: payload || {},
      }

    case USER.GET_ACH_SETTINGS.SUCCESS:
    case USER.UPDATE_ACH_SETTINGS.SUCCESS:
      return {
        ...state,
        pending: false,
        achSettings: payload || {},
      }
    case USER.UPDATE.SUCCESS:
      return {
        ...state,
        pending: false,
        account: payload.account ? { ...(state.account || {}), ...payload.account } : state.account,
        addresses: payload.addresses
          ? { ...(state.addresses || {}), ...payload.addresses }
          : state.addresses,
      }
    case USER.SIGN_AGREEMENT.SUCCESS:
      return {
        ...state,
        pending: false,
        account: { ...state.account, ...payload },
      }

    case USER.UPDATE_PROFILE_IMAGE.SUCCESS:
      return state.account && payload
        ? {
            ...state,
            pending: false,
            account: {
              ...state.account,
              avatar: payload,
            },
          }
        : state

    case USER.GET_ACCOUNT.FAILURE:
    case USER.PATCH_ACCOUNT.FAILURE:
    case USER.GET_ADDRESS.FAILURE:
    case USER.UPDATE_ADDRESS.FAILURE:
    case USER.GET_ACH_SETTINGS.FAILURE:
    case USER.UPDATE_ACH_SETTINGS.FAILURE:
    case USER.SIGN_AGREEMENT.FAILURE:
    case USER.UPDATE.FAILURE:
    case USER.UPDATE_QUESTION_ANSWERS.FAILURE:
    case USER.UPDATE_PASSWORD.FAILURE:
      return {
        ...state,
        pending: false,
        error: payload.error || null,
      }

    case AUTH.LOGOUT.SUCCESS:
      return initialState

    default:
      return state
  }
}
